<template>
  <ul class="visa-types grid-row  grid-gap-3 margin-top-1" :class="[data.fields.styleInstructions, parentClass]">
    <document-renderer
      :key="i"
      v-for="(visaType, i) of data.fields.cardReferences"
      :documentId="visaType.sys.id"
      :additionaldata= "{ instruction:data.fields.styleInstructions }"
    ></document-renderer>
  </ul>
</template>

<script>
export default {
  name: "visa-type-card-encapsulator",
  props: ["data"],
  created() {
    if (this.data.fields.styleInstructions == "all-columns:50-50") {
      this.parentClass = "justify-center";
    } else if (this.data.fields.styleInstructions == "all-center-align") {
      this.parentClass = "justify-center";
    } else {
      this.parentClass = "";
    }
  }
};
</script>
<style lang="scss" scoped>
  .justify-center {
    justify-content: space-evenly !important;
  }
  #contact-us-call-3 {
    margin: 0 auto;
  }
</style>